


























































import { Vue, Component } from "vue-property-decorator";
import { User } from "@/generated/graphql";
import { Routes } from "@/types/core";

@Component({
  name: "Sidebar",
})
export default class Sidebar extends Vue {
  protected readonly Routes = Routes;

  get nameInitials(): string {
    return this.$store.getters["session/nameInitials"];
  }
  get fullName(): string {
    return this.$store.getters["session/fullName"];
  }
  get me(): User {
    return this.$store.getters["session/me"];
  }
}
